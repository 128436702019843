import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { Auth } from "aws-amplify";
import {
  Button,
  TextField,
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "./Loader";
import Dropzone from "./Dropzone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { detailTenant, detailUser, uploadFile } from "../services/api";
import { useDataContext } from "@/contexts/dataProvider";

const LoaderOverlay = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <Loader />
    </Box>
  );
};

const UploadForm: React.FC = () => {
  const { tenantData, updateTenantData, userData, updateUserData } =
    useDataContext();
  const [tenantName, setTenantName] = useState(tenantData?.tenant_name || "");
  const [userName, setUserName] = useState(userData?.user_name || "");
  const [resumeUserName, setResumeUserName] = useState("");
  const [resumeUserCompanies, setResumeUserCompanies] = useState<string[]>([
    "",
  ]);
  const [extension, setExtension] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadedInfo, setUploadedInfo] = useState<string>("");
  const [resetDropzone, setResetDropzone] = useState(false);

  const resetInputs = () => {
    setResumeUserName("");
    setResumeUserCompanies([""]);
    setExtension("");
    setFile(null);
    setErrorMessage("");
    setIsLoading(false);
    setWarningMessage("");
    setOpenDialog(false);
    setUploadedInfo("");
    setResetDropzone(true);
  };

  const fetchUser = async () => {
    if (!tenantData || !userData) {
      const userInfo = await Auth.currentAuthenticatedUser();
      const loginUser = await detailUser({
        tenant_id: userInfo.attributes["custom:tenant_id"],
        email: userInfo.attributes.email,
      });
      const tenant = await detailTenant({
        tenant_id: userInfo.attributes["custom:tenant_id"],
      });
      updateUserData(loginUser);
      updateTenantData(tenant);
      setTenantName(userInfo.attributes["custom:tenant_name"]);
      setUserName(userInfo.attributes["custom:user_name"]);
    } else {
      setTenantName(tenantData?.tenant_name || "");
      setUserName(userData?.user_name || "");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (resetDropzone) {
      setResetDropzone(false);
    }
  }, [resetDropzone]);

  const handleUpload = async () => {
    if (!validateInputs()) {
      return;
    }
    console.log("handleUpload started"); // Debugging line
    console.log("file:", file); // Debugging line
    setIsLoading(true);
    const filteredResumeUserCompanies = resumeUserCompanies.filter(
      (company) => company.trim() !== ""
    );
    if (!file) {
      setErrorMessage("Please select a file to upload.");
      return;
    }

    try {
      console.log("Sending POST request to get signed URL"); // Debugging line
      const result = await uploadFile({
        tenant_name: tenantName,
        user_name: userName,
        resume_user_name: resumeUserName,
        resume_user_companies: filteredResumeUserCompanies,
        extension: extension,
        file: file,
      });

      console.log("Upload result:", result); // Debugging line

      if (result.status === 200 || result.status === 204) {
        console.log("Upload successful, setting uploadSuccess to true.");
        setUploadSuccess(true);
        setUploadedInfo(
          `アップロードしたファイル: ${file?.name}, ユーザー名: ${resumeUserName}`
        );
        resetInputs();
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        console.error("Upload failed:", error.message);
      } else {
        setErrorMessage("An unknown error occurred.");
        console.error("Upload failed:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateInputs = () => {
    if (!file) {
      setWarningMessage("ファイルが選択されていません。");
      return false;
    }
    if (!resumeUserName.trim()) {
      setWarningMessage("ユーザー名が入力されていません。");
      return false;
    }
    const trimmedCompanies = resumeUserCompanies.map((company) =>
      company.trim()
    );
    if (
      trimmedCompanies.length === 0 ||
      trimmedCompanies.every((company) => company === "")
    ) {
      setOpenDialog(true);
      return false;
    }
    return true;
  };

  const handleConfirm = () => {
    setOpenDialog(false);
    handleUpload();
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const addCompanyField = () => {
    setResumeUserCompanies([...resumeUserCompanies, ""]);
  };

  const updateCompanyField = (index: number, value: string) => {
    const updatedCompanies = [...resumeUserCompanies];
    updatedCompanies[index] = value;
    setResumeUserCompanies(updatedCompanies);
  };

  const handleFile = (file: File) => {
    setFile(file);

    // ファイルの拡張子を取得
    const fileExtension = file.name.split(".").pop();
    if (fileExtension) {
      setExtension(`.${fileExtension}`);
    }
  };

  return (
    <Box padding={1}>
      <Box display="flex" justifyContent="center" mt={4}>
        <Box width={800}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            アップロード
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField fullWidth label="会社名" value={tenantName} disabled />
            <TextField fullWidth label="担当者名" value={userName} disabled />
            <TextField
              fullWidth
              label="ユーザー名"
              value={resumeUserName}
              onChange={(e) => setResumeUserName(e.target.value)}
            />
            {resumeUserCompanies.map((company, index) => (
              <React.Fragment key={index}>
                <TextField
                  fullWidth
                  label={`ユーザーの職歴 #${index + 1}`}
                  value={company}
                  onChange={(e) => updateCompanyField(index, e.target.value)}
                />
              </React.Fragment>
            ))}
            <Button startIcon={<AddIcon />} onClick={addCompanyField}>
              ユーザーの職歴を追加
            </Button>
            <Box display="flex" alignItems="center" gap={2}>
              <Dropzone onFile={handleFile} reset={resetDropzone} />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <CircularProgress size={24} />
                  <Typography variant="body2">アップロード中...</Typography>
                </>
              ) : (
                "履歴書・職務経歴書の作成を開始"
              )}
            </Button>
            {isLoading && <LoaderOverlay />}
          </Box>
        </Box>
      </Box>

      {/* 成功時のSnackBarをこちらに1つだけ残します */}
      <Snackbar open={uploadSuccess} onClose={() => setUploadSuccess(false)}>
        <Alert onClose={() => setUploadSuccess(false)} severity="success">
          アップロードに成功しました。AIによる書類作成を開始します。
          <br />
          {uploadedInfo && (
            <div>
              {uploadedInfo.split(", ").map((info, index) => (
                <React.Fragment key={index}>
                  <span>{info}</span>
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            職歴が空欄ですが、間違いないでしょうか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleConfirm} color="primary">
            はい
          </Button>
        </DialogActions>
      </Dialog>

      {/* エラーメッセージ用のSnackBar */}
      {errorMessage && (
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {/* 警告メッセージ用のSnackBar */}
      {warningMessage && (
        <Snackbar
          open={Boolean(warningMessage)}
          autoHideDuration={6000}
          onClose={() => setWarningMessage("")}
        >
          <Alert onClose={() => setWarningMessage("")} severity="warning">
            {warningMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UploadForm;
