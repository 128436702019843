import { JobData } from "@/components/job/types";
import { ResumeData } from "@/components/resumes/types";
import { TenantData } from "@/components/tenant/types";
import UserData from "@/components/users/types";
import { jobListParams } from "@/services/types";
import { Auth } from "aws-amplify";
import axios from "axios";

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

interface PresignedPost {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  };
}

interface ResponsePresignedPost {
  data: PresignedPost;
  status: number;
}

export const getToken = async () => {
  const loginUser = await Auth.currentAuthenticatedUser();
  return loginUser.signInUserSession.idToken.jwtToken;
};

export const uploadFile = async (params: {
  tenant_name: string;
  user_name: string;
  resume_user_name: string;
  resume_user_companies: string[];
  extension: string;
  file: File;
}) => {
  console.log(`${apiUrl}/upload`);

  console.log("params:", params);
  try {
    const token = await getToken();
    const response: ResponsePresignedPost = await axios.post(
      `${apiUrl}/upload`,
      {
        tenant_name: params.tenant_name,
        user_name: params.user_name,
        resume_user_name: params.resume_user_name,
        resume_user_companies: params.resume_user_companies,
        extension: params.extension,
        services: {
          transcription: true,
          translation: false,
          summarization: true,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to get presigned url");
    }

    console.log("response:", response);

    const presignedPostData: PresignedPost = response.data;
    console.log("presignedPostData:", presignedPostData);

    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(
        key,
        presignedPostData.fields[key as keyof typeof presignedPostData.fields]
      );
    });

    // ファイルを追加
    formData.append("file", params.file);

    const result = await axios.post(presignedPostData.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("result:", result);
    return result;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const detailTenant = async (params: { tenant_id: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post<TenantData>(
      `${apiUrl}/tenant/detail`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tenant:", error);
    throw error;
  }
};


export const detailUser = async (params: {
  tenant_id: string;
  email: string;
}) => {
  try {
    const token = await getToken();
    const response = await axios.post<UserData>(
      `${apiUrl}/users/detail`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const listJob = async (params: jobListParams) => {
  const queryParams: any = {};
  if (params.tenant_id) {
    queryParams.tenant_id = params.tenant_id;
  }
  if (params.user_email) {
    queryParams.user_email = params.user_email;
  }
  if (params.status) {
    queryParams.status = params.status;
  }

  try {
    const token = await getToken();
    const response = await axios.post<JobData[]>(
      `${apiUrl}/job/list`,
      {},
      {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error listing job:", error);
    throw error;
  }
};

export const detailJob = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post<JobData>(
      `${apiUrl}/job/detail`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job:", error);
    throw error;
  }
};

export const updateJobStatusDone = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post<JobData>(
      `${apiUrl}/job/update/status-done`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating job status done:", error);
    throw error;
  }
};

export const updateJobStatusDownloaded = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post<JobData>(
      `${apiUrl}/job/update/status-downloaded`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating job status downloaded:", error);
    throw error;
  }
};

export const detailResume = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post<ResumeData>(
      apiUrl + "/resume/detail",
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching resume:", error);
    throw error;
  }
};

export const resumeDownloadGoogleDocx = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post(
      `${apiUrl}/resume/format_and_download/google_resume_docx`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching resume:", error);
    throw error;
  }
};

export const resumeDownloadMSDocx = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post(
      `${apiUrl}/resume/format_and_download/microsoft_resume_docx`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching resume:", error);
    throw error;
  }
};

export const resumeDownloadXlsx = async (params: { uuid: string }) => {
  try {
    const token = await getToken();
    const response = await axios.post(
      `${apiUrl}/resume/format_and_download/resume_xlsx`,
      {},
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching resume:", error);
    throw error;
  }
};
