import React, { useCallback, useState, useEffect } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { Paper, Typography, Snackbar, Alert, Box } from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import MoveUpTwoToneIcon from "@mui/icons-material/MoveUpTwoTone";
import SwipeUpIcon from "@mui/icons-material/SwipeUp";
import { styled } from "@mui/system";

const DropzoneContainer = styled(Paper)(({ theme }) => ({
  border: `3px dashed ${theme.palette.primary.main}`,
  borderRadius: "12px",
  padding: "20px",
  textAlign: "center",
  backgroundColor: "#f9f9f9",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  width: "100%",
  "&:hover": {
    backgroundColor: "rgba(156, 210, 211, 0.2)",
  },
  "&.active": {
    backgroundColor: "rgba(156, 210, 211, 0.2)",
  },
}));

interface DropzoneProps {
  onFile: (file: File) => void;
  reset: boolean;
}

const Dropzone: React.FC<DropzoneProps> = ({ onFile, reset }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (reset) {
      setFileName(null);
      setErrorMessage(null);
    }
  }, [reset]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        setErrorMessage(
          "動画ファイルまたは音声ファイルのみアップロードできます。"
        );
        return;
      }
      setFileName(acceptedFiles[0].name);
      onFile(acceptedFiles[0]);
    },
    [onFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "video/*": [],
      "audio/*": [],
    },
  });

  return (
    <>
      <DropzoneContainer elevation={3} {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          height="100%"
        >
          <MoveUpTwoToneIcon color="primary" />
          <SwipeUpIcon color="primary" />
          <Typography variant="h6" gutterBottom style={{ marginTop: "8px" }}>
            {fileName || "ファイルを選択  "}
            <TouchAppIcon color="primary" />
          </Typography>
        </Box>
      </DropzoneContainer>
      {errorMessage && (
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Dropzone;
