import React from "react";
import styles from "../styles/loader.module.css";

const Loader = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.boxes}>
        <div className={styles.box}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.box}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.box}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.box}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={styles["uploading-text-wrapper"]}>
        <div className={styles["uploading-text"]}>アップロード中...</div>
      </div>
    </div>
  );
};

export default Loader;
