import { useDataContext } from "@/contexts/dataProvider";
import { detailTenant, detailUser, listJob } from "@/services/api";
import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";

const withAuth = (WrappedComponent: FC<any>): FC<any> => {
  const WithAuthWrapper: FC<any> = (props) => {
    const router = useRouter();
    const {
      tenantData,
      updateTenantData,
      userData,
      updateUserData,
      jobList,
      updateJobList,
    } = useDataContext();

    // Add a new state variable to track authentication status
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const checkUser = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const attributes = user.attributes;

          if (!userData) {
            const loginUser = await detailUser({
              tenant_id: attributes["custom:tenant_id"],
              email: attributes.email,
            });
            const tenant = await detailTenant({
              tenant_id: attributes["custom:tenant_id"],
            });
            const jobs = await listJob({
              user_email: attributes.email,
              status: "done",
            });
            updateUserData(loginUser);
            updateTenantData(tenant);
            updateJobList(jobs);
            console.log("loginUser", loginUser);
            console.log("tenant", tenant);
          }
          if (userData && tenantData) {
            const jobs = await listJob({
              user_email: userData.user_email,
              status: "done",
            });
            updateJobList(jobs);
            console.log(jobs);
          }
          if (!user) {
            router.push("/login");
          } else {
            // Set isAuthenticated to true when authentication is complete
            setIsAuthenticated(true);
          }
        } catch (error) {
          router.push("/login");
        }
      };

      checkUser();
    }, [router]); // Add router as a dependency

    // Do not display anything until authentication is complete
    if (!isAuthenticated) {
      return;
    }

    return <WrappedComponent {...props} />;
  };

  // Add displayName
  WithAuthWrapper.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;

  return WithAuthWrapper;
};

// Utility function to get the displayName of a component
function getDisplayName(WrappedComponent: React.ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withAuth;
